<template>
  <div class="unpaid-analytics" v-if="hasPerm('payments.view_analyticaccount')">
    <page-header
      :title="'Synthèse des impayés au ' + today" icon="fas fa-chart-bar"
      :links="getLinks()"
    ></page-header>
    <div class="row">
      <div class="col-12">
        <loading-gif :loading-name="loadingName"></loading-gif>
        <div ref="printMe">
          <div class="hide-here">
            Synthèse des impayés au {{ today }}
          </div>
          <div ref="excelTable" v-if="!isLoading(loadingName)">
            <table class="table table-striped">
              <tr>
                <th>
                  Saison
                </th>
                <th>
                  Code analytique
                </th>
                <th></th>
                <th class="text-right">Montant</th>
              </tr>
              <tr>
                <th>
                  <b-select v-model="schoolYearFilter">
                    <b-select-option v-for="schoolYear of schoolYears" :key="schoolYear.id" :value="schoolYear">
                      {{ schoolYear.name }}
                    </b-select-option>
                  </b-select>
                  <div class="hide-here" v-if="schoolYearFilter">{{ schoolYearFilter.name }}</div>
                </th>
                <th>
                  <b-select v-model="analyticAccountFilter">
                    <b-select-option v-for="elt of analyticAccounts" :key="elt.id" :value="elt">
                      {{ elt.getLabel() }}
                    </b-select-option>
                  </b-select>
                  <div class="hide-here" v-if="analyticAccountFilter">{{ analyticAccountFilter.getLabel() }}</div>
                </th>
                <th><counter-label :counter="filteredElements.length" label="élément"></counter-label></th>
                <th class="text-right">Total: {{ unpaidTotal | currency }}</th>
              </tr>
              <tr v-for="element of filteredElements" :key="element.key">
                <td>
                  {{ element.schoolYear.name }}
                </td>
                <td colspan="2">
                  <span :class="{ italic: element.analyticAccount.id === 0, }">
                    {{ element.analyticAccount.getLabel() }}
                  </span>
                </td>
                <td class="text-right">
                  {{ element.unpaidTotal | currency }}
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from 'moment'
import { mapActions, mapMutations } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import CounterLabel from '@/components/Controls/CounterLabel'
import LoadingGif from '@/components/Controls/LoadingGif'
import PageHeader from '@/components/Layout/PageHeader'
import router from '@/router'
import { makeSchoolYear } from '@/types/schools'
import { makeAnalyticAccount } from '@/types/payments'
import { distinct } from '@/utils/arrays'
import { BackendApi, openDocument } from '@/utils/http'

export default {
  name: 'unpaid-analytics',
  mixins: [BackendMixin],
  components: {
    CounterLabel,
    LoadingGif,
    PageHeader,
  },
  data() {
    return {
      loadingName: 'unpaid-analytics',
      elements: [],
      schoolYears: [],
      analyticAccounts: [],
      schoolYearFilter: null,
      analyticAccountFilter: null,
    }
  },
  watch: {
    loading: function(newValue, oldValue) {},
  },
  computed: {
    today() {
      return moment().format('LLLL')
    },
    unpaidTotal() {
      return this.filteredElements.reduce(
        (acc, cur) => {
          acc += cur.unpaidTotal
          return acc
        },
        0
      )
    },
    filteredElements() {
      return this.elements.filter(
        elt => this.schoolYearFilter.id === -1 || elt.schoolYear.id === this.schoolYearFilter.id
      ).filter(
        elt => this.analyticAccountFilter.id === -1 || elt.analyticAccount.id === this.analyticAccountFilter.id
      )
    },
  },
  mounted() {
    this.onLoaded()
  },
  methods: {
    ...mapActions(['addError']),
    ...mapMutations(['startLoading', 'endLoading']),
    onLoaded() {
      this.loadElements()
    },
    async loadElements() {
      this.startLoading(this.loadingName)
      const url = '/api/unpaid-analytics/'
      const backendApi = new BackendApi('get', url)
      try {
        this.schoolYears = [makeSchoolYear({ id: -1, name: 'Toutes', })]
        this.analyticAccounts = [makeAnalyticAccount({ id: -1, name: 'Tous', })]
        const resp = await backendApi.callApi()
        this.elements = resp.data.map(
          elt => {
            const schoolYear = makeSchoolYear(elt.school_year)
            const analyticAccount = makeAnalyticAccount(elt.analytic_account)
            this.schoolYears.push(schoolYear)
            this.analyticAccounts.push(analyticAccount)
            return {
              key: '' + schoolYear.id + ':' + analyticAccount.id,
              schoolYear: schoolYear,
              analyticAccount: analyticAccount,
              unpaidTotal: elt.unpaid_total,
            }
          }
        )
        this.schoolYears = distinct(this.schoolYears)
        this.analyticAccounts = distinct(this.analyticAccounts)
        this.schoolYearFilter = this.schoolYears[0]
        this.analyticAccountFilter = this.analyticAccounts[0]
      } catch (err) {
        this.addError(this.getErrorText(err))
      }
      this.endLoading(this.loadingName)
    },
    getLinks() {
      return [
        {
          id: 1,
          label: 'Liste des impayés',
          callback: this.viewUnpaidList,
          icon: 'fa fa-file-invoice',
          cssClass: this.isLoading(this.loadingName) ? 'btn-secondary disabled' : 'btn-secondary',
        },
        {
          id: 2,
          label: 'Pdf',
          callback: this.printMe,
          icon: 'fa fa-file-pdf',
          cssClass: this.isLoading(this.loadingName) ? 'btn-secondary disabled' : 'btn-secondary',
        },
        {
          id: 3,
          label: 'Excel',
          callback: this.excelMe,
          icon: 'fa fa-file-excel',
          cssClass: this.isLoading(this.loadingName) ? 'btn-secondary disabled' : 'btn-secondary',
        }
      ]
    },
    async printMe() {
      const docUrl = '/documents/standard/<key>/pdf/'
      const docSlug = 'synthese-impayes-pdf-' + moment().format('YYYY-MM-DD-HH-MM-SS')
      const docContent = this.$refs.printMe.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async excelMe() {
      const docUrl = '/documents/table-to-excel/<key>/'
      const docSlug = 'synthese-impayes-xls-' + moment().format('YYYY-MM-DD-HH-MM-SS')
      const docContent = this.$refs.excelTable.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    viewUnpaidList() {
      router.push({ name: 'invoices-to-pay', })
    },
  },
}
</script>

<style lang="less">
.italic {
  font-style: italic;
}
</style>
